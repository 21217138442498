import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const IndexPage = () => {
  const { t } = useTranslation();
  const parse = require('html-react-parser');
  return (
    <Layout>
      <Seo title={t("Privacy Policy")} />
      <Row>
        <Col className="text-center download-section pt-4 pb-3">
          <h1>{t("Privacy Policy for Snapzy")}</h1>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col className="mt-5">{parse(t("privacy policy translation"))}</Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
